@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap');

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.disable-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

.h-custom-1 {
  height: 40px;
}

.bg-custom-1 {
  background-color: #ffffffb0;
}

.bg-custom-2 {
  background-color: #5250E1;
}


.hover-bg-custom-2:hover {
  background-color: rgb(27, 26, 86);
}

.text-custom-1 {
  color: #ab4c8a;
}

.text-custom-2 {
  color: #5250E1;
}

.text-custom-3 {
  color: #B6B8ED;
}

.vertical-grad-custom-1 {
  /* background-color: #5250E1; */
  background-image: linear-gradient(rgb(248 113 113 / 0), #5250E1);
}

.horizontal-grad-custom-1 {
  /* background-color: #5250E1; */
  background-image: linear-gradient(to right, #19193b, #5250E1);
}

.simple-border {
  border: 1px solid #d2d2d2;
}

.multi_color_heading {
  /* #d3527a */
  background: -webkit-linear-gradient(313deg, #b93166, #5250E1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text_effect {
  background: -webkit-linear-gradient(313deg, #000000, #000000);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

}

.text_effect:hover {
  background: -webkit-linear-gradient(313deg, #b93166, #5250E1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gradient-text {
  background-image: linear-gradient(to right, #b93166 -1%, #5250E1 50%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-border {
  border-image:
    linear-gradient(to bottom,
      #ef4949, #5250E1) 1 100%;
}

.gradient-border-button {
  border-image: linear-gradient(to right, #ef4949, #5250E1) 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.active {
  animation: pulse 1.5s infinite;
}


/* src/components/VerticalTabs.css */

.tab-content {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.tab-content.active {
  opacity: 1;
  transform: translateY(0);
}

.background {
  background: linear-gradient(rgba(252, 252, 252, 0.73), rgba(255, 255, 255, 0.9)), url("./assets/images/Web-Interface/compressedLanding.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-landing {
  background-image: url('./assets/images/Web-Interface/compressedLanding.png');
  background-size: cover;

}

.bg-landing-gradiant {
  background-image: linear-gradient(rgba(255, 255, 255, 0.94), rgba(255, 255, 255, 0.836)), url('./assets/images/Web-Interface/compressedLanding.png');
  background-size: cover;

}

.bannerBg {
  background: linear-gradient(275deg, #472eb4, #b93166);
}

.wmde-markdown {
  background-color: white !important;
  color: black !important;
}